import {
    Directive,
    TemplateRef,
    ViewContainerRef,
    Input,
    OnInit,
  } from '@angular/core';
import { SsoAuthServiceNew } from '../services/sso-auth-new.service';
  
  @Directive({
    selector: '[appHasRole]',
  })
  export class HasRoleDirective implements OnInit {
    constructor(
      private templateRef: TemplateRef<any>,
      private viewContainer: ViewContainerRef,
      private ssoAuthService: SsoAuthServiceNew
    ) {}
  
    roles: string[] = [];
  
    @Input() set appHasRole(roles: string[]) {
      this.roles = roles;
    }
  
    ngOnInit() {
      this.ssoAuthService.currentRpUser$.subscribe((rpUser) => {
        
        if (rpUser &&
          rpUser.currentRole &&
          this.roles.includes(rpUser.currentRole.name)) {

          this.viewContainer.clear();
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
      })
    }
  }
  