import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, first, map, take, timeout } from 'rxjs/operators';
import { IPrimaryList, IRole, ISecondaryList } from 'src/app/shared/model/IGeneralSetup';
import { IUser, RpUser } from 'src/app/shared/model/user.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DataService } from 'src/app/shared/services/data.service';
import { GeneralSetupService } from 'src/app/shared/services/general-setup.service';
import { NotificationBarService } from 'src/app/shared/services/notification-bar.service';
import { SecurityService } from 'src/app/shared/services/security.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { PublicConfigService } from '../../services/public.config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NavBarService } from '../../services/nav-bar.service';
import { SsoAuthServiceNew } from '../../services/sso-auth-new.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  public showSidebar = false;
  public showProfileMenu = false;

  public changeRoleSelected = false;

  public challengeSiteLinks = [];

  public PrimaryList: Array<IPrimaryList>;
  public SecondaryList: Array<ISecondaryList> = [];
  public submissionIndex: number;
  public submissionSubIndex: number;
  public secondaryNavIndex: number;
  public secondarySubNavIndex: number;
  //public roles: Array<IRole>;
  //public userRole: string;

  public currentRpUser$: Observable<RpUser| null>;
  scrollY = 0;

  scrollbarConfig = {
    wheelPropagation: false
  }

  // navigation behaviour on scroll
  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    this.scrollY = window.scrollY;
    this.closeDropdownSubmission();
    this.closeProfileMenu();
    this.closeDropdownSidebar();
    this.globeMenuSelected = false;
  }

  // loaders
  loaderLogo: boolean = true;
  loaderPrimaryNav: boolean = true;
  loaderGlobeMenu: boolean = true;
  loaderNotifications: boolean = true;
  //loaderProfile: boolean = true;
  loaderHelp: boolean = true;
  loaderSidebar: boolean = true;

  globeMenuSelected = false;

  // default (initial) competition info
  competitionName = 'Competition Name';
  competitionLogo = 'assets/images/carrot-logo-icon.png';
  competitionLogoAltText = 'Did you enter ALT text?';
  accountSettingsURL = '';

  // compiled route - used to determine active page
  compiledURL = this.router.url.toLowerCase();

  // subscriptions
  // router subscription - used to check for navigation start (when navigation to another route)
  routerSub = Subscription.EMPTY;
  // platform challenge site links subscription - returns obs
  platformChallengeSiteLinksSub = Subscription.EMPTY;
  // competition info sub
  competitionInfoSub = Subscription.EMPTY;

  navbarRefreshSub = Subscription.EMPTY;

  constructor(
    private generalSetupService: GeneralSetupService,
    private ssoAuthService: SsoAuthServiceNew,
    public router: Router,
    public dataService: DataService,
    private storage: StorageService,
    private commonService: CommonService,
    private securityService: SecurityService,
    private notificationBarService: NotificationBarService,
    private publicConfigService: PublicConfigService,
    private sanitizer: DomSanitizer,
    private navBarService: NavBarService
  ) {

    // compiling active url - used to display active page, custom need for general setup
    if (this.compiledURL === '/general-setup/details' ||
      this.compiledURL === '/general-setup/stages' ||
      this.compiledURL === '/general-setup/branding' ||
      this.compiledURL === '/general-setup/content') {
      this.compiledURL = '/general-setup';
    }
  }

  ngOnInit() {
    // determine active route - used to display active page
    this.routerSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.compiledURL = event.url;
        // compiling active url - used to display active page, custom need for general setup
        if (this.compiledURL === '/general-setup/details' ||
          this.compiledURL === '/general-setup/stages' ||
          this.compiledURL === '/general-setup/branding' ||
          this.compiledURL === '/general-setup/content') {
          this.compiledURL = '/general-setup';
        }
        this.showSidebar = false;
      }
    });

    // get all needed information
    this.getCompetitionInformation();
    this.getPrimaryList();
    this.getSecondaryList();
    this.getChallengeSiteLinks();

    this.currentRpUser$ = this.ssoAuthService.currentRpUser$;
    
    this.platformChallengeSiteLinksSub = this.generalSetupService.platformChallengeSiteListener().subscribe(res => {
      setTimeout(() => {
        this.loaderGlobeMenu = false;
      }, 100);
      this.challengeSiteLinks = res;
    });

    // this won't work for localhost, it only works on live environment
    this.accountSettingsURL = this.publicConfigService.getCarrotSSOConfig().ssoBaseUrl + `/app/${this.publicConfigService.getCarrotSSOConfig().ssoClientId}/user/account-settings`;

    // TODO - update when API is available or remove completely
    // disabling notifications & help loaders
    this.loaderNotifications = false;
    this.loaderHelp = false;

    // subscribing to obs that is used to refresh nav items
    this.navbarRefreshSub = this.navBarService.refreshNav$.subscribe(resp => {
      if (resp === true) {
        this.getPrimaryList();
        this.getSecondaryList();
      };
    });
  }

  getCompetitionInformation() {
    this.competitionInfoSub = this.commonService.competitionInfo.pipe(filter(res => !!res)).subscribe(() => {
      setTimeout(() => {
        this.loaderLogo = false;
      }, 1000);
      const competitionNameFromStorage = this.storage.retrieve('competitionName');
      if (competitionNameFromStorage !== null) {
        this.competitionName = competitionNameFromStorage;
      };
      const logoFromStorage = this.storage.retrieve('competitionLogo');
      if (logoFromStorage !== null) {
        this.competitionLogo = logoFromStorage;
      };
      const logoAltTextFromStorage = this.storage.retrieve('competitionLogoAltText');
      if ((logoAltTextFromStorage !== '') && (logoAltTextFromStorage !== null)) {
        this.competitionLogoAltText = logoAltTextFromStorage;
      };
    });
  }

  async getPrimaryList() {
    try {
      const resp: any = await this.navBarService.primaryList().toPromise();
      this.loaderPrimaryNav = false;
      this.PrimaryList = resp.data as Array<IPrimaryList>;
    } catch (error) {
      console.log(error);
    }
  }

  async getSecondaryList() {
    try {
      const resp: any = await this.navBarService.secondaryList().toPromise();
      this.loaderSidebar = false;
      this.SecondaryList = resp.data as Array<ISecondaryList>;
    } catch (error) {
      console.log(error);
    }
  }

  selectGlobeMenu() {
    this.globeMenuSelected = !this.globeMenuSelected;
  }

  getChallengeSiteLinks() {
    this.generalSetupService.GetChallengeSiteLinks().subscribe((response) => {
      this.generalSetupService.updatePlatformChallengeSiteLinks(response['data']);

    }, (err) => {
      console.log(err);
    });
  }

  toggleSubmission(index) {
    this.submissionIndex = this.submissionIndex === index ? null : index;
  }

  closeDropdownSubmission() {
    this.submissionIndex = null;
    this.submissionSubIndex = null;
  }

  toggleSubSubmission(index) {
    this.submissionSubIndex = this.submissionSubIndex === index ? null : index;
  }

  toggleSidebar() {
    this.showSidebar = !this.showSidebar;
    if (this.showSidebar === false) {
      // this.toggleSecondary(null);
    } else {
      this.toggleSecondary(null);
      this.SecondaryList.forEach((element, index) => {
        if (element.navigationItemHref === null) {
          element.children.forEach(child => {
            if (child.navigationItemHref === this.compiledURL) {
              this.toggleSecondary(index);
            }
          })
        }
      });
    }
  }

  toggleProfileMenu() {
    this.showProfileMenu = !this.showProfileMenu;
  }

  async setRole(roleID) {  
    
    await this.dataService.setRole({ roleId: roleID }).pipe(first()).toPromise();
      
    const updatedUser = await this.ssoAuthService.reloadPlatformUser();

    this.getPrimaryList();
    this.getSecondaryList();

    this.router.navigate(['/dashboard']);

    this.notificationBarService.showNotification('update', `<p>You are now logged in as <b>${updatedUser.currentRole.name}</b></p>`, 8000);
    this.showProfileMenu = false;
    this.changeRoleSelected = !this.changeRoleSelected;
  }

  toggleSecondary(index): void {
    this.secondaryNavIndex = this.secondaryNavIndex === index ? null : index;
  }

  toggleSubSecondary(index) {
    this.secondarySubNavIndex = this.secondarySubNavIndex === index ? null : index;
  }

  closeDropdownSidebar() {
    this.showSidebar = false;
    // this.toggleSecondary(null);
  }

  chooseUrl(secNavName) {
    let selectedItem = this.SecondaryList.find(item => item.navigationItemName === secNavName);
    if (selectedItem.navigationItemHref === this.compiledURL) {
      return true;
    } else if (selectedItem.children.length) {
      for (let i = 0; i < selectedItem.children.length; i++) {
        if (selectedItem.children[i].navigationItemHref === this.compiledURL) {
          return true;
        } else if (selectedItem.children[i].children.length) {
          for (let j = 0; j < selectedItem.children[i].children.length; j++) {
            if (selectedItem.children[i].children.navigationItemHref === this.compiledURL) {
              return true;
            }
          }
        }
      }
    }
  }

  closeProfileMenu() {
    this.showProfileMenu = false;
  }

  async logout() {
    await this.ssoAuthService.logout();
  }

  ngOnDestroy() {
    // unsubscribe from...
    // router sub
    this.routerSub.unsubscribe();
    // platform challenge site links sub
    this.platformChallengeSiteLinksSub.unsubscribe();
    // general info sub
    this.competitionInfoSub.unsubscribe();
    // refresh nav items sub
    this.navbarRefreshSub.unsubscribe();
  }

  challengeLink(href: string) {
    if (href.startsWith('/')) {
      return this.sanitizer.bypassSecurityTrustUrl(`${this.publicConfigService.feBaseUrl}${href}`);
    } else {
      return this.sanitizer.bypassSecurityTrustUrl(href);
    }
  }
}
