<div class="top-overlay" *ngIf="isMobile">
  <div class="logo-wrap">
    <img [src]="overlayLogo" [alt]="competitionName + ' logo'">
  </div>
  <div class="content">
    <h1>Optimized for Desktop</h1>
    <p>For the best experience, please switch to a desktop or laptop computer.</p>
  </div>
  <div class="contact-us">
    <p>For any questions or concerns, please contact our <a [href]="'mailto:' + supportEmailAddress" target="_blank">support team</a>.</p>
  </div>
</div>
<div class="top-overlay cookie-overlay" *ngIf="!cookiesEnabled">
  <div class="brand-stripe"></div>
  <div class="logo-wrap">
    <img [src]="overlayLogo" [alt]="competitionName + ' logo'">
  </div>
  <div class="content">
    <h1>Cookies Required</h1>
    <p>It appears that the site cannot load because cookies are fully disabled. To ensure the best experience on our website, please enable cookies in your browser settings.</p>
    <p>Thank you for your understanding and cooperation.</p>
  </div>
  <div class="contact-us">
    <p>For any questions or concerns, please contact our <a [href]="'mailto:' + supportEmailAddress" target="_blank">support team</a>.</p>
  </div>
</div>
<div
  class="table-print"
  *ngIf="!isMobile && cookiesEnabled"
  [ngClass]="{'impersonation-bar-shown': impersonationBannerShown}"
>
  <thead class="table-header">
    <tr>
      <td>
        <div class="header-space">
          <div class="left-part">
            <span class="logo-wrap">
              <img [src]="competitionLogo" [alt]="competitionLogoAltText">
            </span>
            <span class="comp-name">{{ competitionName }}</span>
          </div>
          <div class="right-part">
            <span class="title print-compiled-header-title">{{ compiledHeaderTitle }}</span>
          </div>
        </div>
      </td>
    </tr>
  </thead>
  <div class="table-body">
    <div class="table-row">
      <div class="table-data content-main-wrapper">
        <div class="main" [ngClass]="{'loginPageVisible': loginPageVisible}">
          <app-notification-bar></app-notification-bar>
          <div class="notification-impersonation" *ngIf="ssoAuthService.impersonationInfo$ | async as impersonationInfo">
            <div class="content">
              <span class="icon-wrapper">
                <span class="icon material-icons-round">info</span>
              </span>
              <div class="content-inner">
                <p><b>{{ impersonationInfo.impersonatedByFullName }}</b> you are now impersonating <b>{{ impersonationInfo.impersonatedFullName }}</b>.</p>
              </div>
              <button
                (click)="stopImpersonate()"
                class="end-impersonation button button-secondary button-secondary--smaller"
              >
                End Impersonation <span class="icon material-icons-outlined">group_off</span>
              </button>
            </div>
          </div>
          
          <div *ngIf="!hideNav && (ssoAuthService.currentRpUser$ | async)"
            class="custom-navbar-outer-wrapper on-top"
            #customNavbarOuterWrapper
            (click)="customNavbarOuterWrapperOnTop()"
          >
            <app-nav-bar class="navbar navbar-expand-lg navbar-light"></app-nav-bar>
          </div>

          <div
            class="login-page-background"
            *ngIf="loginPageVisible && loginPageBackgroundImage"
            [ngClass]="{'shown': loginPageVisible && loginPageBackgroundImage}"
            [ngStyle]="{'background-image': loginPageBackgroundImage}"
          ></div>

          <div class="custom-main-outer-wrapper">
            <div
              class="pcoded-main-container"
              id="pcoded-main-container"
              [ngClass]="{'dashboard-active': isDashboardActive}"
            >
              <div class="main-container-shape-on-scroll"></div>
              <div class="pcoded-wrapper">
                <div class="pcoded-content">
                  <div class="pcoded-inner-content">
                    <div class="main-body">
                      <div class="page-loader" [ngClass]="{'stopLoader': pageWrapperStopLoader }">
                        <div class="loader-overlay"></div>
                        <div class="loader-inner">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 200 200"
                          >
                            <radialGradient id="a6" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
                              <stop offset="0" stop-color="#4A74FF" stop-opacity="0"></stop>
                              <stop offset=".3" stop-color="#4A74FF" stop-opacity=".3"></stop>
                              <stop offset=".6" stop-color="#4A74FF" stop-opacity=".6"></stop>
                              <stop offset=".8" stop-color="#4A74FF" stop-opacity=".9"></stop>
                              <stop offset="1" stop-color="#4A74FF" stop-opacity="1"></stop>
                            </radialGradient>
                            <circle
                              transform-origin="center" fill="none"
                              stroke="url(#a6)" stroke-width="12" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0"
                              cx="100" cy="100" r="70"
                            >
                              <animateTransform
                                type="rotate" attributeName="transform" calcMode="spline"
                                dur="1" values="0;360" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"
                              ></animateTransform>
                            </circle>
                            <circle
                              transform-origin="center" fill="none" opacity=".2"
                              stroke="#4A74FF" stroke-width="12" stroke-linecap="round"
                              cx="100" cy="100" r="70"
                            ></circle>
                          </svg>
                        </div>
                      </div>
                      <div class="page-wrapper" [ngClass]="{'stopLoader': pageWrapperStopLoader }">
                        <router-outlet></router-outlet>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <tfoot class="table-footer">
    <tr>
      <td>
        <div class="footer-space">
          <div class="footer-print">
            <div class="logo-wrap">
              <img src="assets/images/carrot-logo.png" width="129" alt="Carrot Logo in Footer">
            </div>
            <div class="text-wrap">
              &copy; {{ currentYear }} All rights reserved
            </div>
          </div>
        </div>
      </td>
    </tr>
  </tfoot>
</div>
<app-loader id="loader"></app-loader>
<app-footer class="footer" [ngClass]="{'footer-loginPageVisible': loginPageVisible}" *ngIf="!hideFooter && !isMobile && cookiesEnabled"></app-footer>
<ng-container>
  <app-scroll-to-top
    class="to-top"
    [ngClass]="{
      'show-scrollTop': windowScrolled
    }"
    (scrollToTop)="onScrollToTop()"
  >
  </app-scroll-to-top>
</ng-container>
