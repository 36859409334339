import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { filter} from 'rxjs/operators';
import { CompetitionInformation } from "src/app/shared/model/common.model";
import { CommonService } from 'src/app/shared/services/common.service';
import { PublicConfigService } from "src/app/shared/services/public.config.service";
import { SsoAuthServiceNew } from "src/app/shared/services/sso-auth-new.service";
import { SsoAuthDisplayService } from "src/app/shared/services/sso-auth.display.service";
import { StorageService } from 'src/app/shared/services/storage.service';
import { TabTitleService } from "src/app/shared/services/tab-title.service";

@Component({
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {
  competitionLogo: string = '';
  competitionLogoAltText: string = '';

  showLogin: boolean = false;
  userEmail: string = '';
  passwordInputType: string = 'password';
  userPassword: string = '';
  passwordButtonTooltip: string = 'Show password';

  errorMessage: string = '';
  showErrorMessage: boolean = false;

  loaderShown: boolean = false;
  formSubmitting: boolean = false;

  // conditional
  hideLogin: boolean = false;
  loginWithNotVerifiedEmail: boolean = false;
  resendVerificationWithVerifiedEmail: boolean = false;
  // the email which was already verified
  verifiedEmail: string = '';

  isRegistrationOpen: boolean;

  currentRpUserSubscription: Subscription;

  feBaseUrl = '';

  constructor(
    private storage: StorageService,
    private commonService: CommonService,
    public ssoAuthService: SsoAuthServiceNew,
    private tabTitleService: TabTitleService,
    public ssoAuthDisplayService: SsoAuthDisplayService,
    private router: Router,
    private publicConfig: PublicConfigService
  ) {

  }

  async ngOnInit() {
    this.tabTitleService.setTabTitle('Login');

    this.feBaseUrl = this.publicConfig.feBaseUrl;
    
    this.commonService.competitionInfo
      .pipe(filter(res => !!res))
      .subscribe(() => {
        const logoFromStorage = this.storage.retrieve(CompetitionInformation.loginWidgetLogoUrl);
        if (logoFromStorage !== null) {
          this.competitionLogo = logoFromStorage;
        };
        const logoAltTextFromStorage = this.storage.retrieve(CompetitionInformation.brandingsLogoAltText);
        if (logoAltTextFromStorage !== null) {
          this.competitionLogoAltText = logoAltTextFromStorage;
        };
        this.isRegistrationOpen =  this.commonService.isRegistrationOpen();
        this.showLogin = true;
      });
  }

  showPasswordButtonClicked() {
    if (this.passwordInputType === 'password') {
      this.passwordInputType = 'text';
      this.passwordButtonTooltip = 'Hide password';
    } else {
      this.passwordInputType = 'password';
      this.passwordButtonTooltip = 'Show password';
    }
  }

  keyDownHandler(e: KeyboardEvent) {
    if (['Enter', 'NumpadEnter'].includes(e.key)) {
      this.onSubmit();
    };
  }

  async onSubmit() {
    // check if we are currently submitting the form
    if (!this.formSubmitting) {
      // show loading animation
      this.loaderShown = true;
      this.formSubmitting = true;

      // can this return error on afAuth.signInWithEmailAndPassword failing?
      // similar as on SSO, we need to hide the loader if this doesn't passes

      try {
        await this.ssoAuthService.loginWithCredentials(this.userEmail, this.userPassword);
        
        if(!this.ssoAuthService.isEmailVerified) {
          this.loginWithNotVerifiedEmail = true;
          this.hideLogin = true;
        }

        // hide loading animation
        this.loaderShown = false;
        this.formSubmitting = false;

      }
      catch(error) {
        const errorCode = error.code;
        this.errorMessage = error.message;
        switch (errorCode) {
          case "auth/user-not-found":
          case "auth/invalid-email":
          case "auth/user-disabled":
          case "auth/wrong-password":
          case "auth/account-exists-with-different-credential":
            this.errorMessage = "Login failed: wrong email or password.";
            break;
          case "auth/too-many-requests":
            this.errorMessage = "Your account was locked after too many failed login attempts. Please try again later.";
            break;
          case "auth/internal-error":
          case "auth/invalid-user-token":
          default:
              this.errorMessage = "Login failed: internal error."
        }
        this.showErrorMessage = true;
        this.loaderShown = false;
        this.formSubmitting = false;
      }
    }
  }

  async resendVerificationEmail() {
    this.resetLoginPage();
    try
    {
      const response = await this.ssoAuthService.resendVerifyEmail();
      if (response.success) {

        this.ssoAuthDisplayService.showGenericScreen(
          "Verification email sent",
          response.data.message,
          ["Login", "Forgot Password"]
        )
      }
    }
    catch(err){
      this.ssoAuthDisplayService.handleApiError(err);
    }
  }

  resetLoginPage() {
    this.userEmail = "";
    this.userPassword = "";
    this.hideLogin = false;
    this.loginWithNotVerifiedEmail = false;
    this.resendVerificationWithVerifiedEmail = false;
  }

  ngOnDestroy() {
    // console.log("login.component -> ngOnDestroy");
    if(this.currentRpUserSubscription) {
      this.currentRpUserSubscription.unsubscribe();
    }
  }
}
